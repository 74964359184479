import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Infrastructure">
    <h3>Data Protection</h3>
    <section>
      Quantic provides a consultative, best practice-based approach to solving business needs and mitigating risk. We have a long, successful history of
      architecting data protection strategies that focus on backup, business continuity and disaster recovery. Benefit from our architects who have more than 15
      years of data protection experience and real-world expertise with industry leading solutions.
      <br/>
      <br/>
      Our tools-assisted analysis and engineering-led solutions have delivered measurable results to our customers:
      <br/>
      <br/>

      <ul>
        <li>Reduced operating costs.</li>
        <li>Meet compliance and regulatory requirements.</li>
        <li>Consolidation of multiple backup environments.</li>
        <li>Emphasis on achieving RTO and RPO.</li>
        <li>Effective management of data growth.</li>
        <li>Dependable backup strategies.</li>
        <li>Cloud integration for archival data.</li>
        <li>Data center migration.</li>
      </ul>
    </section>
    <br/>
    <h3>Storage</h3>
    <section>
      Quantic has invested significant resources into understanding storage industry. Quantic has the expertise to architect, implement, manage, and support
      the right storage solution for your workload environment and align it with business objectives to make the most impact.
      <br/>
      <br/>

      With the right approach, architecture, and deployment, storage can provide competitive advantages and reduce the capital and operational costs of your
      data centers.
      <br/>
      <br/>
      <strong>Flash Storage:</strong> In today`s fast pace, business is all about speed and agility and Flash is the high-octane storage media behind it. Our
      commitment to future innovation continues to increase the value of Flash for your applications and your business.
      <br/>
      <br/>
      <strong>Flash array:</strong> It provides vastly superior performance; fewer spikes in latency, better disaster recovery, support of real-time analytics,
      much faster data transfer rates, and the ability to free IT staff to focus on other tasks. All-Flash Arrays provide the foundation for next-generation
      business applications, and the All-Flash data center.
      <br/>
      <br/>
      <strong>Object Storage:</strong> An object is a piece of data paired with any associated metadata that provides context about the bytes contained within
      the object. Two things the data and metadata together—make an object. The Data stored in objects is uncompressed and unencrypted, and the objects
      themselves are arranged in object stores
    </section>
    <br/>
    <h3>Data Center & Cloud</h3>
    <section>
      We know there are challenges to implement Hybrid or Private Cloud. Whether moving from public to private cloud or build your own private, or hybrid cloud
      solution, we’ll help you achieve your cloud vision. Our enterprise service delivery experience, coupled with industry leading technology partners, enables
      us to assess your workload and application environment and create a cloud strategy that’s right for your business.
      <br/>
      <br/>

      Quantic Cloud Services include:
      <br/>
      <br/>

      <ul>
        <li>Cloud Consulting Services – Get support for every phase of the private or hybrid cloud transition – strategy, analysis, design, and operations
          management.
        </li>
        <li>Cloud Readiness Assessment – Understand how business processes and technology maturity impact cloud strategies through analysis.</li>
        <li>Cloud Proof of Concept – Validate your use case, determine infrastructure readiness, identify integration requirements, and establish success
          criteria.
        </li>
        <li>Cloud Platform Alignment – Assess workload requirements to determine which cloud platform – public, private, or hybrid – is the optimal solution.
        </li>
        <li>Cloud Platform for Crypto Currency Mining: Design and Implement a Hosting solution for Bit miners with ability to scale up to 500 miners.</li>
        <li>Cloud Platform for Crypto Currency Mining: Design and Develop software and integration tools to connect with Cryptocurrency exchanges.</li>
      </ul>
    </section>
    <br/>
  </Layout>
);

export default Page;
